
import React, {
    useEffect, useState
} from 'react';
import {
    useParams
} from "react-router-dom";
import { Casher, SheetRepresentation } from './Casher';
import { CodeScanner } from './CodeScanner';

interface Props {
}

interface States {
    sheet: SheetRepresentation

}

interface UrlParameters {
    id: string
}

export const EventPage: React.FC = () => {

    const params = useParams<UrlParameters>()
    const sheetTitle = decodeURIComponent(params.id)

    const [sheet, setSheet] = useState<SheetRepresentation | null>(null);

    let casher: Casher | null

    function onTextDetected(eventItemId: string) {
            if (casher) {
                casher.onTextDetected(eventItemId)
            }
    }

    useEffect(() => {
        if (!params.id) {
            return
        }

        gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: '1bwoyWfMHXUbS_OoJdATpJKGg1EJTGYM_vhoWDqFxWNs',
            range: `${sheetTitle}!A:Z`
        }).then(response => {
            let sheet = new SheetRepresentation(response.result.values)
            setSheet(sheet)
        })
    }, [params.id])

    if (sheet) {
        return <div>
            <CodeScanner
                detected={onTextDetected}
            />
            <Casher sheet={sheet}
                ref={(ref) => casher = ref }
            />
        </div>
    } else {
        return <h1>{sheetTitle}を読み込んでいます...</h1>
    }
}
