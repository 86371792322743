
import React from 'react';
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';

interface Props {
}

interface States {
    sheets: gapi.client.sheets.Sheet[]
}

class EventList extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sheets: []
        };
      }

    scope = 'https://www.googleapis.com/auth/spreadsheets.readonly'
    clientId = "523023878124-ublaa98v0sttmfo0q5jhrpeksv68qmee.apps.googleusercontent.com"
    DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];

    componentDidMount() {
        gapi.client.sheets.spreadsheets.get({
            spreadsheetId: '1bwoyWfMHXUbS_OoJdATpJKGg1EJTGYM_vhoWDqFxWNs',
            //            ranges: 'A:K'
        }).then(response => {
            console.log(response)
            if (!response.result.sheets) {
                console.error('sheets empty')
                return
            }

            const sheets = response.result.sheets.filter( sheet => 
                sheet.properties?.title?.match(/^E\d\d_\d{4}/)
            )

            this.setState({
                sheets
            })
        })
    }

    render() {
        return (
            <List component="nav" aria-label="secondary mailbox folders">
            {
                    this.state.sheets.map((sheet) => {
                        const props = sheet.properties 
                        if (props?.title) {
                            const to = "/events/" + encodeURIComponent( props.title )                            
                            return (
                                <ListItemText key={props.sheetId}><Link to={to}>{props.title}</Link></ListItemText>
                            )
    
                        } else {
                            return null
                        }

                    })
                }
            </List>
        )
    }
}

export { EventList }
