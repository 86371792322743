import React, { createContext } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const GoogleAuthContext = createContext()

class GoogleAuth extends React.Component {
    state = {
        token: null,
        profile: null,
        loading: true,
        error: null,
    }
    clientId = "523023878124-ublaa98v0sttmfo0q5jhrpeksv68qmee.apps.googleusercontent.com"

    render() {
        if (this.state.loading) {
            return <h1>loading...</h1>
        } else if (this.state.error) {
            return <Alert severity="error">
                <AlertTitle>{this.state.error.error}</AlertTitle>
                { this.state.error.details }
            </Alert>
        } else if (this.state.token) {
            return (
                <GoogleAuthContext.Provider value={this.state}>
                    {this.props.children}
                </GoogleAuthContext.Provider>
            )
        } else {
            return <button onClick={() => this.onClick()}>
                login
            </button>

        }
    }

    onClick() {
        window.gapi.auth2.getAuthInstance().signIn().then(response => {
            this.onReceiveInitResponse(response)
        }, (error) => {
            this.setState({ error, loading: false })
            console.error(error)
        })
    }

    async onReceiveInitResponse(response) {
        await this.loadClient()

        const auth = window.gapi.auth2.getAuthInstance();
        const user = auth.currentUser.get()

        if (auth.isSignedIn.get()) {
            const token = user.getAuthResponse()
            const profile = user.getBasicProfile()

            this.setState({
                token: token,
                profile: profile,
                loading: false,
            })
            console.log('logged in')
        } else {
            this.setState({ loading: false })
            console.log('not logged in')
        }
    }


    async componentDidMount() {

        window.gapi.load('client:auth2', () => {
            const scope = [
                'email',
                'profile',
                'https://www.googleapis.com/auth/spreadsheets'
            ].join(' ')

            window.gapi.auth2.init({
                clientId: this.clientId,
                scope,
                autoLoad: true,
                fetchBasicProfile: true,
            }).then(response => {
                this.onReceiveInitResponse(response)
            }, (error) => {
                this.setState({ error, loading: false })
                console.error(error)
            })
        });

    }

    async loadClient() {
        const discoveryDocs = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];
        return new Promise((resolve, reject) => {
            window.gapi.client.init({
                discoveryDocs,
            }).then(resolve, reject)
        })
    }

    responseGoogle(user) {
        const auth = user.getAuthResponse()
        const profile = user.getBasicProfile()
        this.setState({ auth, profile })
    }
    responseFailure(error) {
        console.error(error)
    }

}
export { GoogleAuth, GoogleAuthContext };
