
import React from 'react';
import { ItemPair, EventItem, RecaratEventId } from './models/EventItem';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

interface Props {
    items: ItemPair[],
    onChangeQuantity: (eventItemId: RecaratEventId, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const formatter = new Intl.NumberFormat('en-us', {}).format

class Purchases extends React.Component<Props> {

    private total(): string {
        return formatter(this.props.items.reduce((n, item) => {
            return n + item.item.price * item.quantity
        }, 0))
    }

    private certificate(item: EventItem): React.ReactNode | null {
        if (item.certificate && String(item.certificate).trim().length > 0) {
            return (
                <span>
                    <InsertDriveFileIcon
                        color="secondary"
                        style={{
                            fontSize: 14,
                            position: 'relative',
                            top: 1
                        }}
                    />
                    {item.certificate}
                </span>
            )
        } else {
            return null
        }
    }

    render() {
        return <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>品名</TableCell>
                        <TableCell>単価</TableCell>
                        <TableCell>個数</TableCell>
                        <TableCell>小計</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        this.props.items.map((item) => {
                            return (
                                <TableRow key={item.item.recaratItemId}>
                                    <TableCell>
                                        {
                                            item.item.eventItemId
                                        } {
                                            item.item.name
                                        } {
                                            this.certificate(item.item)
                                        }
                                    </TableCell>
                                    <TableCell>{formatter(item.item.price)}</TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.quantity} onChange={(ev) => {
                                                this.props.onChangeQuantity(item.item.eventItemId, ev)
                                            }} /></TableCell>
                                    <TableCell>{formatter(item.item.price * item.quantity)}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                    <TableRow key="total-label">

                        <TableCell colSpan={3}></TableCell>
                        <TableCell>合計</TableCell>
                    </TableRow>
                    <TableRow key="total">
                        <TableCell colSpan={3}></TableCell>
                        <TableCell>{this.total()}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}

export { Purchases }
