
import React from 'react';
import {
    Button
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Purchases } from './Purchases';
import * as Sentry from '@sentry/react';
import { GoogleAuthContext } from './GoogleAuth'

import { EventItem, ItemPair, RecaratEventId } from './models/EventItem';
const formatter = new Intl.NumberFormat('en-us', {}).format

interface Props {
    sheet: SheetRepresentation
}

interface States {
    items: ItemPair[]
    isModalOpen: boolean
}

class SheetRepresentation {
    keyToIndex: { [key: string]: number; }
    items: { [key: string]: EventItem } = {}
    requiredFields: { [key: string]: string; } = {
        recaratItemId: 'アイテムID',
        eventItemId: 'イベントID',
        category: '種別',
        name: '石名',
        price: '実販売価格',
        certificate: '鑑定機関名'
    }

    constructor(values: any) {
        this.keyToIndex = {}
        // const labelToName: [
        // '重複チェック', '売却CHECK', 'イベントID', '会計用', 'アイテムID', '種別', '石名', '原価/ct', '重量', '販売価格/ct', '実販売価格', '鑑定機関名'
        // ]

        let headerFound = false

        values.forEach((row: string[]) => {
            if (headerFound) {
                const item = new EventItem(row, this.keyToIndex)
                this.items[item.eventItemId] = item
                return
            }

            if (this.isRowHeader(row)) {
                for (let key in this.requiredFields) {
                    const label = this.requiredFields[key]
                    const index = row.indexOf(label)
                    this.keyToIndex[key] = index
                }
                headerFound = true
            }
        })

        if (!headerFound) {
            const error = new Error("header not found. some required columns are missing.")
            console.log(this.keyToIndex)
            Sentry.captureException(error);
            alert(error.message)
        }
    }

    isRowHeader(cols: string[]): boolean {
        for (let field of Object.values(this.requiredFields)) {
            if (!cols.find(v => v === field)) {
                return false
            }
        }

        return true
    }
    numberOfItems(): number {
        return Object.keys(this.items).length
    }
}

class Casher extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            items: [],
            isModalOpen: false,
        };
    }

    onChangeQuantity = (eventItemId: RecaratEventId, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log('onchangeq')
        const index = this.state.items.findIndex(item => item.item.eventItemId === eventItemId)
        if (index < 0) {
            console.warn("item not found for eventItemId", eventItemId)
            return
        }
        var item = this.state.items[index]
        const value = parseInt(event.target.value)
        if (isNaN(value)) {
            return
        }

        const items = this.state.items.slice(0)
        if (value > 0) {
            item.quantity = value
            items.splice(index, 1, item)
            this.setState({ items })
        } else {
            items.splice(index, 1)
            this.setState({ items })
        }
    }

    private addItemId(eventItemId: string) {
        //        const element = (document.getElementById('item_id') as HTMLInputElement)

        const item = this.props.sheet.items[eventItemId]
        if (!item) {
            return false
        }

        const alreadyExisting = this.state.items.find(item => item.item.eventItemId === eventItemId)
        if (alreadyExisting) {
            return false
        }

        this.setState({
            items: this.state.items.concat(new ItemPair(item))
        })
        return true
    }

    onTextDetected = (eventItemId: string) => {
        // E03_007 or recaratItemId
        if (eventItemId.match(/^(E\d+_\d+|\d{8})$/)) {
            this.addItemId(eventItemId)
            const element = document.getElementById('item_id')
            if (element) {
                (element as HTMLInputElement).value = ''
            }
            window.navigator.vibrate(500)
        } else {
            console.warn("invalid format", eventItemId)
        }
    }

    purchases(profile: gapi.auth2.BasicProfile): any[] {
        let now = new Date().toLocaleString('sv', { timeZoneName: 'short' }).split(' ').slice(0, 2).join(' ')

        // 時間	イベントid	アイテムid	石名	販売価格	数量	会計担当者
        return this.state.items.map(item => {
            return [
                now,
                item.item.eventItemId,
                item.item.recaratItemId,
                item.item.name,
                item.item.price,
                item.quantity,
                profile.getEmail()
            ]
        })
    }
    onSubmit = () => {
        this.setState({ isModalOpen: true })
    }

    onConfirm = (profile: gapi.auth2.BasicProfile) => {
        this.setState({ isModalOpen: false })

        const valueRangeBody: gapi.client.sheets.ValueRange = {
            range: 'A2',
            majorDimension: 'DIMENSION_UNSPECIFIED',
            values: this.purchases(profile)
        }

        gapi.client.sheets.spreadsheets.values.append({
            spreadsheetId: '1_9M6jT1_9_DrbWibG0ls7aU5MeDX4kT-lUdXfz752uk',
            range: "A2",
            insertDataOption: "INSERT_ROWS",
            valueInputOption: "RAW"
        }, valueRangeBody).then(response => {
            console.log(response)
            this.setState({
                items: []
            })
        }, (reason) => {
            const error = reason.result.error
            console.error('error: ' + error.message);
            Sentry.captureException({error: JSON.stringify(error.message)});
            alert(error.message)
        })
    }

    casher(): React.ReactNode {
        return (
            <div>
                <Purchases
                    items={this.state.items}
                    onChangeQuantity={this.onChangeQuantity}
                />
                <div>
                    <p>
                        {this.props.sheet.numberOfItems()} items loaded
                    </p>
                </div>

                <Button color="primary"
                    fullWidth={true}
                    size="large"
                    variant="contained"
                    onClick={this.onSubmit}
                >会計する</Button>
            </div>
        )
    }

    handleClose: any = () => {
        this.setState({ isModalOpen: false })
    }

    formattedTotal(): string {
        const sum = this.state.items.reduce( (n, item) => {
            return n + item.total()
        }, 0)
        return formatter(sum)
    }

    render() {
        return <GoogleAuthContext.Consumer>
        {
          (props: any) => {
            const { profile } = props
            return this.content(profile)
          }
        }
      </GoogleAuthContext.Consumer>
    }

    totalQuantity() {
        return this.state.items.reduce((total, pair) => {
            return total + pair.quantity
        }, 0)
    }

    content(profile: gapi.auth2.BasicProfile): React.ReactNode {
        return <div>
            {this.casher()}
            <Dialog
                open={this.state.isModalOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">会計金額の確認</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <b>{this.totalQuantity()}</b>点
                        合計 ¥<b>{ this.formattedTotal() }</b>です。会計をしますか?
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        いいえ
          </Button>
                    <Button onClick={ () => this.onConfirm(profile) } color="primary" autoFocus>
                        はい
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    }
}

export { Casher, SheetRepresentation }
