

export type RecaratEventId = string

class EventItem {
    recaratItemId: string = ""
    eventItemId: RecaratEventId = ""
    // category: string
    name: string = ""
    price: number = 0
    certificate: string = ""

    constructor(cols: any[], keyToIndex: { [key: string]: number; }) {
        for (let key in keyToIndex) {
            let index = keyToIndex[key]
            Object.assign(this, { [key]: cols[index] })
        }
        this.price = Number(String(this.price).replace(/\D/g, ''))
    }
}


class ItemPair {
    item: EventItem
    quantity: number

    constructor(item: EventItem) {
        this.item = item
        this.quantity = 1
    }

    total(): number {
        return this.quantity * this.item.price
    }
}


export { EventItem, ItemPair}
