import React from 'react';
import { GoogleAuth, GoogleAuthContext } from './GoogleAuth'
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/apm';

import { EventList } from './EventList'
import { EventPage } from './EventPage'
import { Header } from './Header'

import { builtAt } from './built';
import packagejson from "../package.json";

import { Box, Container, Grid, Toolbar } from '@material-ui/core';

const versionSignature = (new Date(builtAt * 1000)).toISOString()

class Index extends React.Component {
  render() {
    return <Redirect to="/events" />
  }
}

interface Props {
}

interface States {
  text: string
}

class App extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
        text: ""
    };
    this.initializeApp()
  }

  initializeApp() {
    if (document.location.hostname !== 'localhost') {
      Sentry.init({
        dsn: "https://52e5d1ab1a7742b1a091803f58b4db86@o126654.ingest.sentry.io/5402689",
        release: 'recarat-casher@' + packagejson.version,
        integrations: [
          new Integrations.Tracing(),
        ],
        tracesSampleRate: 1.0,
      });
    }
  }

  detected = (text: string) => {
    this.setState({text})
  }

  render() {
    return <div className="App">
      <header className="App-header">
        <div style={{ width: '98%' }} >
          <GoogleAuth>
            <GoogleAuthContext.Consumer>
              {
                (props: any) => {
                  const { profile } = props
                  return this.routes(profile)
                }
              }
            </GoogleAuthContext.Consumer>
          </GoogleAuth>
          <Box style={{ fontSize: 'xx-small', textAlign: "right" , margin: 8}}>
            v{packagejson.version} {versionSignature}
          </Box>
        </div>
      </header>
    </div>
  }

  routes(profile: gapi.auth2.BasicProfile): React.ReactNode {
    return (
      <Box >
        <Header profile={profile} />
        <Toolbar />
        <Container fixed>
          <Grid item xs={12}>
            <Router>
              <Route path="/" exact component={Index} />
              <Route path="/events/:id" render={() => <EventPage />} />
              <Route path="/events" exact render={() => <EventList />} />
            </Router>
          </Grid>
        </Container>
      </Box>

    )
  }
}


export default App;
